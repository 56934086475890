import SetupAxios from '../../../../setup/axios/SetupAxios'

export const Post = async <T>(endpoint: string, data: any): Promise<T> => {

    try {
        const response = await SetupAxios.post<T>(endpoint, data)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error
    }
}