import axios, {AxiosInstance} from 'axios'
import {localStorageKey, localStorageValue} from '../../app/modules/all-pages'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL
let isRedirectingToLogin = false // Flag to prevent infinite redirects


const SetupAxios: AxiosInstance = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000,
});

SetupAxios.interceptors.request.use(
    config => {

        // if (window.location.pathname === '/login') {
        //     return Promise.reject("No API calls on login page")
        // }
        // Modify request config here (e.g., add auth token)
        if (localStorageValue) {
            config.headers.Authorization = `Bearer ${localStorageValue}`
        }

        return config
    },
    error => {
        return Promise.reject(error)
    },
)

SetupAxios.interceptors.response.use(
    response => {
        // Handle the response data here
        return response
    },
    error => {
        const {response} = error

        if (response) {
            const {status} = response
            if (status === 401) {
                // Clear the auth token from localStorage (if needed)
                // window.localStorage.removeItem(localStorageKey)
                // window.localStorage.removeItem('_userInterface')
                // window.localStorage.removeItem('_clientCreationDate')

                // Redirect to login
                // window.location.href = '/login'; // Change this to your login route

                return
            }
        }

        return Promise.reject(error)
    },
)


// SetupAxios.interceptors.response.use(
//     response => {
//         // Handle the response data here
//         return response;
//     },
//     error => {
//         // Handle errors here
//         return Promise.reject(error);
//     }
// );

export default SetupAxios
