import React, {FC} from 'react'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useUser} from '../../../../app/modules/context/types'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
    toolbarButtonIconSizeClass = 'svg-icon-1 svg-icon-primary'

const Topbar: FC = () => {

    const {firstname, lastname, images} = useUser()

    const image = images.length ? images[0]['path'] : toAbsoluteUrl('/media/avatars/blank.png')

    return (
        <div className="d-flex align-items-stretch flex-shrink-0">

            {/* begin::User */}
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                 id="kt_header_user_menu_toggle"
            >
                {/* begin::Toggle */}
                <div
                    className={clsx('cursor-pointer symbol d-flex align-items-center', toolbarUserAvatarHeightClass)}
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="bottom"
                >
                    <span className="cursor-pointer text-gray-600 text-hover-primary fs-6 fs-sm-5 me-3 text-capitalize">
                        {firstname} {lastname}
                    </span>
                    <img src={image} alt="MyWebforce" style={{objectFit: 'cover'}} />
                </div>
                <HeaderUserMenu />
                {/* end::Toggle */}
            </div>
            {/* end::User */}

        </div>
    )
}

export {Topbar}