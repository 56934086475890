import React, {FC, useContext, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ChartsWidget7, ChartsWidget8} from '../../../_metronic/partials/widgets'
import {ProductContext, ProductReturn} from '../../GeneraleVariables'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {subDays} from 'date-fns'
import {DateRange, usePeriod, UseWindowSize} from '../../modules/all-pages'
import {QueryFunctionContext, QueryKey, useQueries} from 'react-query'
import ChartDashboard1 from './components/ChartDashboard1'
import {ChartDashboard4} from './components/ChartDashboard4'
import {ContactsDashboard} from './components/ContactsDashboard'
import {Button} from '../report/context/types'
import {Get} from '../../modules/functions/get/Get'
import {useDashboardQueries} from './useDashboardQueries'
import {initialData, InterfaceData} from './ts'
import {loadingState} from '../../../global'
import {Announcement} from './components/Announcement'

// interface InterfaceData {
//     conversion: number
//     numberVisit: number
//     chartOptions: {
//         series: number[]
//         categoriesXAxis: string[]
//     }
//     cleverPhone: {
//         has_cleverPhone: boolean;
//         generalAppelRecu: number;
//         click_sur_tel: number;
//     }
//     allFormsReceived: number
//     announcement: {
//         button: Button | null;
//         icon: {
//             src: string
//             title: string
//             text: string
//         }[] | []
//         image: string | null;
//         sub_title: string;
//         text: string;
//         title: string;
//     } | null
// }

const DASHBOARD_CONVERSION = process.env.REACT_APP_DASHBOARD_CONVERSION || 'conversion'
const CALL_TRACKING = process.env.REACT_APP_CALL_TRACKING_GENERAL || 'calltraking-statistic'
const WEB_FORMS = process.env.REACT_APP_WEB_FORMS_HOME_WEB_FORMS || 'statistiques-generales'
const STATISTICS_VISIT = process.env.REACT_APP_STATISTICS_VISIT || 'statistics-visit'
const CALL_LOG = process.env.REACT_APP_CALL_TRACKING_CALL_LOG || 'calltraking-journal-appel'
const ANNOUNCEMENT = process.env.REACT_APP_DASHBOARD_ANNOUNCEMENT || 'v2/annonce-homepage'

const styles = {
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: '1fr',
        gridGap: 10,
    },
    gridMd: {
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
    gridSm: {
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
}


const DashboardPage: FC = () => {

    const productsStatus = useContext<ProductReturn[]>(ProductContext)

    const [rangeValue, setRangeValue] = React.useState<ValueType>([subDays(new Date(), 29), new Date()]),
        [loadingData, setLoadingData] = useState<'idle' | 'pending' | 'fulfilled' | 'rejected'>('idle'),
        // [data, setData] = useState<InterfaceData>({
        //     conversion: 0,
        //     numberVisit: 0,
        //     chartOptions: {
        //         series: [],
        //         categoriesXAxis: [''],
        //     },
        //     cleverPhone: {
        //         has_cleverPhone: true,
        //         generalAppelRecu: 0,
        //         click_sur_tel: 0,
        //     },
        //     allFormsReceived: 0,
        //     announcement: null,
        // }),
        [data, setData] = useState<InterfaceData>({...initialData}),
        [loading, setLoading] = useState<loadingState>('idle'),
        [dateTimeStart, dateTimeEnd] = rangeValue,
        excludedNames = ['Mailing', 'Agenda', 'Ereputaion', 'Statistiques']



    const period = usePeriod(dateTimeStart, dateTimeEnd)

    const statusTrue = useMemo(
        () => productsStatus.filter(item =>
            item.status === 1 && !excludedNames.includes(item.name) || item.status === 0 && ['Calltraking'].includes(item.name),
        ),
        [productsStatus],
    )

    useDashboardQueries({period, statusTrue, setData, setLoading})

    const {innerWidth} = UseWindowSize()
    const getResponsiveStyles = () => {
        if (innerWidth <= 480) {
            return {...styles.grid, ...styles.gridSm}
        } else if (innerWidth <= 768) {
            return {...styles.grid, ...styles.gridMd}
        } else {
            return styles.grid
        }
    }


    return (
        <>

            {/* RangeValue */}
            <div className="row mb-8">
                <div className="col-12 d-flex justify-content-end align-items-center">

                    <DateRange rangeValue={rangeValue}
                               setRangeValue={setRangeValue}
                               loadingStatus={loadingData}
                               setLoadingStatus={(status) => setLoadingData(status)}
                               className="d-flex justify-content-end align-items-center"
                    />
                </div>
            </div>

            <div className="mb-5" style={getResponsiveStyles()}>
                <div>
                    <ChartDashboard1 bg={'#e1006d'}
                                     iconPath={'/media/icons/duotune/general/gen060.svg'}
                                     body={{total: data.numberVisit, text: 'Visites'}}
                                     to={'/statistiques/s-seo'}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={'#0094f3'}
                                     iconPath={'/media/icons/duotune/arrows/arr029.svg'}
                                     body={{total: data.conversion, text: 'Conversions'}}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={'#e1006d'}
                                     iconPath={'/media/icons/duotune/general/gen005.svg'}
                                     body={{total: data.allFormsReceived, text: 'Formulaires'}}
                                     to={'/web-formulaires/tableau-de-bord-wf'}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={'#0094f3'}
                                     iconPath={'/media/icons/duotune/communication/com014.svg'}
                                     body={{total: data.cleverPhone.generalCallReceived, text: 'Appels'}}
                                     to={'/call-tracking/journal-appels'}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={'#e1006d'}
                                     iconPath={'/media/icons/duotune/coding/cod006.svg'}
                                     body={{
                                         total: data.cleverPhone.clickOnTel,
                                         text: 'Nombre de clics sur le téléphone',
                                     }}
                                     to={'/call-tracking/journal-appels'}
                    />
                </div>
            </div>

            <div className="row d-flex justify-content-center">
                <div className="col-xl-4 card card-xl-stretch">
                    <ContactsDashboard />
                </div>
                <div className="col-xl-8">
                    <ChartDashboard4 className={'card-xl-stretch'}
                                     chartOptions={data.chartOptions}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Announcement />
                </div>
            </div>


            {/*begin::ROW-04 */
            }
            {/*card cursor */
            }
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-6">
                    <ChartsWidget7 className={'card-xl-stretch'} />
                </div>
                <div className="col-xl-6">
                    <ChartsWidget8 className={'card-xl-stretch'} />
                </div>
            </div>
            {/*end::ROW-04 */
            }


        </>
    )
}


const DashboardWrapper: FC = () => {

    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage />
        </>
    )
}

export {DashboardWrapper}