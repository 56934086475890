import {FC} from 'react'
import {TextInputInterface} from './types'

const TextInput: FC<TextInputInterface> = ({formik, name, label, value = undefined, flex}) => {

    const fieldProps = formik.getFieldProps(name);

    return (
        <div className={`row mb-2 mb-lg-6 d-flex flex-${flex}`}>

            <label htmlFor={name} className={`${flex === "column" ? "col-12" : "col-3"} d-flex align-items-center col-form-label fw-bold fs-7`}>{label}</label>

            <div className={`${flex === "column" ? "col-12" : "col-9"} fv-row`}>
                <input type="text"
                       className="form-control form-control-sm form-control-solid mb-0"
                       placeholder={label}
                       id={name}
                       {...fieldProps}
                       value={value ?? fieldProps.value}
                       readOnly={!!value}
                />
                {formik.touched[name] && formik.errors[name] && (
                    <div
                        className="fv-plugins-message-container my-1">
                        <div className="fv-help-block">
                            <span role="alert" className="text-danger p-2">{formik.errors[name]}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export {TextInput}