import React, {Dispatch, FC, SetStateAction, useCallback, useRef, useState} from 'react'
import {KTSVG} from '../../helpers'
import * as Yup from 'yup'
import {TextInput} from '../content/forms/TextInput'
import {useFormik} from 'formik'
import {Post} from '../../../app/modules/functions/post/Post'

interface StatusInterface {
    status: number
    color: string
    message: string
    icon: string
}

type Props = {
    index: number | string
    textIcon: boolean
    data: typeof initialValues
    setRefresh: Dispatch<SetStateAction<boolean>>
}

const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    number: '',
    companyName: '',
    street: '',
}

const Schema = Yup.object().shape({
    first_name: Yup.string().min(3, 'Au moins 3 lettres').max(30, 'Maximum 30 lettres'),
    last_name: Yup.string().min(3, 'Au moins 3 lettres').max(30, 'Maximum 30 lettres'),
    companyName: Yup.string().min(3, 'Au moins 3 lettres').max(50, 'Maximum 50 lettres'),
    street: Yup.string().min(7, 'Au moins 7 lettres').max(500, 'Maximum 500 lettres'),
    email: Yup.string().email('Format d\'e-mail incorrect').min(12, 'Au moins 12 symboles').max(50, 'Maximum 50 symboles'),
    number: Yup.string().matches(/^\d+$/, 'Seulement les chiffres'),
})

const ONE_CONTACT = process.env.REACT_APP_MAILING_ONE_CONTACT_CREATE || '/v2/subscribers'

const AddToContacts: FC<Props> = ({index, data, setRefresh, textIcon}) => {

    const modalRef = useRef<HTMLDivElement | null>(null)
    const closeRef = useRef<HTMLButtonElement | null>(null)
    const [formState, setFormState] = useState<{
        loading: boolean;
        statusMessage: StatusInterface;
    }>({loading: false, statusMessage: {status: -1, color: '', icon: '', message: ''}})

    const handleStatusMessage = useCallback(({status, message, color, icon}: StatusInterface) => {

        setFormState((prev) => ({
            ...prev,
            statusMessage: {status, message, color, icon},
            loading: false,
        }))

        setTimeout(() => {
            if (closeRef.current) {
                formik.resetForm()
                closeRef.current?.click()
                setFormState((prev) => ({...prev, statusMessage: {status: -1, message: '', color: '', icon: ''}}))
                setRefresh(true)
            }
        }, 2000)
    }, [])


    const onSubmit = async (values: typeof initialValues, {setSubmitting}: {
        setSubmitting: (isSubmitting: boolean) => void
    }) => {

        setFormState((prev) => ({...prev, loading: true}))

        try {
            const {status, message} = await Post<any>(ONE_CONTACT, values)

            handleStatusMessage({
                status: status === 400 ? 400 : 200,
                message: status === 400 ? message : 'Contact ajouté avec succès!',
                color: status === 400 ? 'danger' : 'success',
                icon: status === 400 ? '/general/gen050.svg' : '/general/gen048.svg',
            })
        } catch (err) {
            console.error('Error submitting the form:', err)
        } finally {
            setFormState((prev) => ({...prev, loading: false}))
            // setSubmitting(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            first_name: data.first_name || '',
            last_name: data.last_name || '',
            email: data.email || '',
            number: data.number || '',
            companyName: data.companyName || '',
            street: data.street || '',
        },
        validationSchema: Schema,
        enableReinitialize: true,
        onSubmit,
    })

    const renderTextInput = (label: string, name: string) => (
        <div className="col-12 col-sm-6">
            <TextInput label={label} name={name} formik={formik} flex={'row'} />
        </div>
    )


    return (
        <>
            <div className="text-center">
                <button type="button"
                        className="btn btn-sm btn-light-primary btn-flex btn-active-primary"
                        data-bs-toggle="modal"
                        data-bs-target={`#kt_modal_add_to_contacts_${index}`}
                        aria-describedby="control-id-click"
                >
                    <KTSVG path="/media/icons/duotune/abstract/abs011.svg"
                           className={`svg-icon svg-icon svg-icon-3 ${!textIcon && 'me-0'}`} />
                    {textIcon && 'Créer'}
                </button>
            </div>
            <div className="modal fade" id={`kt_modal_add_to_contacts_${index}`} aria-hidden="true" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered mw-900px">
                    <div className="modal-content">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="modal-header">
                                <h2>Ajouter aux contacts</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                                </div>
                            </div>
                            <div className="modal-body p-3 p-sm-5  p-md-7">
                                <div className="row">
                                    {renderTextInput('Prénom', 'first_name')}
                                    {renderTextInput('Nom', 'last_name')}
                                    {renderTextInput('E-mail', 'email')}
                                    {renderTextInput('Adresse', 'street')}
                                    {renderTextInput('Entreprise', 'companyName')}
                                    {renderTextInput('Téléphone', 'number')}
                                    <div className="col-12">
                                        {
                                            formState.statusMessage.status > 0
                                                ? (
                                                    <div
                                                        className={`alert alert-${formState.statusMessage.color} d-flex align-items-center p-3 mt-3 mb-0`}>
                                                        <span
                                                            className={`svg-icon svg-icon-2hx svg-icon-${formState.statusMessage.color} me-4`}>
                                                          <KTSVG
                                                              path={`/media/icons/duotune${formState.statusMessage.icon}`}
                                                              className="svg-icon-2x" />
                                                        </span>
                                                        <div className="d-flex flex-column">
                                                            <span>{formState.statusMessage.message}</span>
                                                        </div>
                                                    </div>
                                                ) : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-sm btn-primary me-2 px-6"
                                        disabled={formState.loading}>
                                    {formState.loading ? (
                                        <span className="indicator-progress d-block">
                    S'il vous plaît, attendez... <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                                    ) : (
                                        'Sauvegarder'
                                    )}
                                </button>
                                <button type="button"
                                        className="btn btn-sm btn-light me-2 px-6"
                                        data-bs-dismiss="modal"
                                        onClick={() => formik.resetForm()}
                                        ref={closeRef}
                                >
                                    Fermer
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export {AddToContacts}

